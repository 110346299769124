import React, { useState } from "react";
import { X, Send, CheckCircle } from "lucide-react";

interface FollowupMessageProps {
  firstName: string;
  surname: string;
  branch: string;
  idNumber: string;
  email: string;
  loanAmount: number;
  onClose: () => void;
}

const FollowupMessage: React.FC<FollowupMessageProps> = ({
  firstName,
  surname,
  branch,
  idNumber,
  email,
  loanAmount,
  onClose,
}) => {
  const [sent, setSent] = useState(false);
  const [sending, setSending] = useState(false);

  // Format the loan amount as a currency string
  const formattedAmount = new Intl.NumberFormat('en-NA', {
    style: 'currency',
    currency: 'NAD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(loanAmount);

  // Pre-formatted message that will be sent via WhatsApp
  const defaultMessage = `Please attend to my loan application I just submitted from the website to the ${branch} branch for an amount of ${formattedAmount}.\n\nMy details:\nName: ${firstName} ${surname}\nID Number: ${idNumber}\nEmail: ${email}\n\nKind Regards,\n${firstName} ${surname}`;
  const [message, setMessage] = useState(defaultMessage);

  const handleSendMessage = async () => {
    setSending(true);
    
    try {
      // Get branch-specific WhatsApp number
      const branchNumbers: Record<string, string> = {
        "Windhoek": "264811424048",
        "Okahandja": "264817909909",
        "Katutura": "264811424048", 
        "Rundu": "264811424048",    
        "Oshakati": "264811424048", 
        "Walvisbay": "264811424048",
        "Swakopmund": "264811424048"
      };
      
      const whatsappNumber = branchNumbers[branch] || branchNumbers["Windhoek"];
      
      // Encode message for URL
      const encodedMessage = encodeURIComponent(message);
      
      // Create WhatsApp URL
      const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodedMessage}`;
      
      // Open WhatsApp in a new tab
      window.open(whatsappUrl, "_blank", "noopener,noreferrer");
      
      // Mark as sent
      setSent(true);
      
      // Close modal after a delay
      setTimeout(() => {
        onClose();
      }, 3000);
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setSending(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-xl shadow-xl max-w-md w-full p-6 relative">
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-400 hover:text-gray-600"
        >
          <X size={24} />
        </button>

        {sent ? (
          <div className="text-center py-8">
            <CheckCircle size={64} className="mx-auto text-green-500 mb-4" />
            <h3 className="text-xl font-bold text-gray-800 mb-2">
              Message Sent!
            </h3>
            <p className="text-gray-600">
              Your WhatsApp message has been prepared. Thank you for choosing CashNet Finance!
            </p>
          </div>
        ) : (
          <>
            <h3 className="text-2xl font-bold text-gray-800 mb-2">
              Speed Up Your Application
            </h3>
            <p className="text-gray-600 mb-6">
              Send a WhatsApp message to our {branch} team to get priority attention for your loan application!
            </p>

            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-medium mb-2">
                Your Message:
              </label>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="w-full p-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500 h-32 resize-none bg-gray-50"
              />
            </div>

            <div className="flex space-x-4">
              <button
                onClick={onClose}
                className="flex-1 py-3 px-4 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition-colors"
              >
                No Thanks
              </button>
              <button
                onClick={handleSendMessage}
                disabled={sending}
                className="flex-1 bg-green-600 text-white py-3 px-4 rounded-lg hover:bg-green-700 transition-colors flex items-center justify-center gap-2"
              >
                {sending ? "Opening..." : "Send Now"}
                <Send size={18} />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FollowupMessage;
