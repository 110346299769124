import React from "react";

const HowItWorks: React.FC = () => {
  return (
    <div className="relative w-full bg-gray-900 overflow-hidden">
      <div className="relative max-w-7xl mx-auto px-4 py-20">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-4xl font-bold text-white mb-6">How It Works</h2>
          <p className="text-lg text-gray-100 leading-relaxed">
            Get the financial support you need in minutes. Our simple process
            lets you apply online or visit any branch, receive instant approval
            decisions, and access competitive rates. With flexible repayment
            options and transparent terms, managing your loan is effortless.
            Need assistance? Our friendly team is ready to help.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
