import React from "react";
import OshakatiLoanForm from "../../components/locationForms/OshikatiLoanForm";

const OshakatiLocation: React.FC = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">
        Apply for a Loan - Oshakati
      </h1>
      <OshakatiLoanForm />
    </div>
  );
};

export default OshakatiLocation;
