import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Menu, X, ChevronDown } from "lucide-react";
import cashnetLogo from "../assets/cashnetlogo.webp";

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLocationsOpen, setIsLocationsOpen] = useState(false);
  const [isMobileLocationsOpen, setIsMobileLocationsOpen] = useState(false);
  const locationsRef = useRef<HTMLDivElement>(null);
  const mobileLocationsRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const locations = [
    "Windhoek",
    "Okahandja",
    "Katutura",
    "Rundu",
    "Oshakati",
    "Walvisbay",
    "Swakopmund"
  ];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (locationsRef.current && !locationsRef.current.contains(event.target as Node)) {
        setIsLocationsOpen(false);
      }
      if (mobileLocationsRef.current && !mobileLocationsRef.current.contains(event.target as Node)) {
        setIsMobileLocationsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleApplyNow = () => {
    navigate("/apply");
    setIsMenuOpen(false);
  };

  return (
    <header className="bg-white shadow-sm">
      <nav className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 justify-between items-center">
          {/* Logo */}
          <div className="flex items-center">
            <Link to="/">
              <img
                src={cashnetLogo}
                alt="CashNetFinance"
                className="h-12 w-auto"
              />
            </Link>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex md:items-center md:space-x-8">
            <Link
              to="/"
              className="font-bold text-gray-700 hover:text-[#F14C07]"
            >
              Home
            </Link>
            <Link
              to="/calculator"
              className="font-bold text-gray-700 hover:text-[#F14C07]"
            >
              Calculator
            </Link>
            <Link
              to="/about"
              className="font-bold text-gray-700 hover:text-[#F14C07]"
            >
              About
            </Link>
            <Link
              to="/contact"
              className="font-bold text-gray-700 hover:text-[#F14C07]"
            >
              Contact Us
            </Link>
            <div ref={locationsRef} className="relative">
              <button
                onClick={() => setIsLocationsOpen(!isLocationsOpen)}
                className="font-bold text-gray-700 hover:text-[#F14C07] flex items-center"
              >
                Locations
                <ChevronDown className={`ml-1 w-4 h-4 transition-transform ${isLocationsOpen ? "rotate-180" : ""}`} />
              </button>
              {isLocationsOpen && (
                <div className="absolute z-10 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 transition-all duration-200 ease-in-out origin-top-right">
                  <div className="py-1">
                    {locations.map((location) => (
                      <Link
                        key={location}
                        to={`/locations/${location.toLowerCase()}`}
                        className="block px-4 py-2 text-sm font-bold text-gray-700 hover:bg-orange-50 hover:text-[#F14C07]"
                        onClick={() => setIsLocationsOpen(false)}
                      >
                        {location}
                      </Link>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <button
              onClick={handleApplyNow}
              className="bg-[#F14C07] text-white px-4 py-2 rounded-md hover:bg-[#E04E1A] font-bold"
            >
              Apply Now
            </button>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-gray-700 hover:text-[#F14C07]"
              aria-label="Toggle menu"
            >
              {isMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <div className="md:hidden">
            <div className="space-y-1 pb-3 pt-2">
              <Link
                to="/"
                className="block px-3 py-2 font-bold text-gray-700 hover:bg-orange-50 hover:text-[#F14C07]"
              >
                Home
              </Link>
              <Link
                to="/calculator"
                className="block px-3 py-2 font-bold text-gray-700 hover:bg-orange-50 hover:text-[#F14C07]"
              >
                Calculator
              </Link>
              <Link
                to="/about"
                className="block px-3 py-2 font-bold text-gray-700 hover:bg-orange-50 hover:text-[#F14C07]"
              >
                About
              </Link>
              <Link
                to="/contact"
                className="block px-3 py-2 font-bold text-gray-700 hover:bg-orange-50 hover:text-[#F14C07]"
              >
                Contact Us
              </Link>
              <div ref={mobileLocationsRef}>
                <button
                  onClick={() => setIsMobileLocationsOpen(!isMobileLocationsOpen)}
                  className="flex items-center justify-between w-full px-3 py-2 font-bold text-gray-700 hover:bg-orange-50 hover:text-[#F14C07]"
                >
                  <span>Locations</span>
                  <ChevronDown className={`w-4 h-4 transition-transform ${isMobileLocationsOpen ? "rotate-180" : ""}`} />
                </button>
                {isMobileLocationsOpen && (
                  <div className="bg-gray-50 pl-6">
                    {locations.map((location) => (
                      <Link
                        key={location}
                        to={`/locations/${location.toLowerCase()}`}
                        className="block px-3 py-2 font-bold text-gray-700 hover:bg-orange-100 hover:text-[#F14C07]"
                        onClick={() => {
                          setIsMobileLocationsOpen(false);
                          setIsMenuOpen(false);
                        }}
                      >
                        {location}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
              <div className="px-3 py-2">
                <button
                  onClick={handleApplyNow}
                  className="w-full bg-[#F14C07] text-white px-4 py-2 rounded-md hover:bg-[#E04E1A] font-bold"
                >
                  Apply Now
                </button>
              </div>
            </div>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Header;
