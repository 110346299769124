import React from "react";
import SwakopmundLoanForm from "../../components/locationForms/SwakopmundLoanForm";

const SwakopmundLocation: React.FC = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">
        Apply for a Loan - Swakopmund
      </h1>
      <SwakopmundLoanForm />
    </div>
  );
};

export default SwakopmundLocation;
