import React from "react";
import KatuturaLoanForm from "../../components/locationForms/KataturaLoanForm";

const KatuturaLocation: React.FC = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">
        Apply for a Loan - Katutura
      </h1>
      <KatuturaLoanForm />
    </div>
  );
};

export default KatuturaLocation;
