import React from "react";
import RunduLoanForm from "../../components/locationForms/RunduLoanForm";

const RunduLocation: React.FC = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">
        Apply for a Loan - Rundu
      </h1>
      <RunduLoanForm />
    </div>
  );
};

export default RunduLocation;
