import React from "react";
import idDocument from "../assets/id-document-icon.svg";
import bankStatement from "../assets/bank-statement-icon.svg";
import payslips from "../assets/payslips-icon.svg";

const Requirements: React.FC = () => {
  return (
    <div className="w-full bg-gray-100 py-16">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-4">
          REQUIREMENTS
        </h2>
        <div className="h-1 w-24 bg-[#F85B20] mx-auto mb-16"></div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 justify-items-center">
          <div className="flex flex-col items-center">
            <div className="mb-4">
              <img src={idDocument} alt="Namibian ID" className="w-24 h-24" />
            </div>
            <h3 className="text-xl text-gray-800 text-center">Namibian ID</h3>
          </div>

          <div className="flex flex-col items-center">
            <div className="mb-4">
              <img
                src={bankStatement}
                alt="Bank Statement"
                className="w-24 h-24"
              />
            </div>
            <h3 className="text-xl text-gray-800 text-center">
              Latest 3 Months Bank Statement
            </h3>
          </div>

          <div className="flex flex-col items-center">
            <div className="mb-4">
              <img src={payslips} alt="Payslips" className="w-24 h-24" />
            </div>
            <h3 className="text-xl text-gray-800 text-center">
              2 Latest payslips
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Requirements;