import React from "react";
import { MapPin, Phone, Clock, Mail, FileText } from "lucide-react";
import { Link } from "react-router-dom";

// Branch information (duplicated from Branches component with modifications)
const BranchInfo = {
  windhoek: {
    name: "Windhoek Branch",
    phones: ["+264 81 142 4048"],
    email: "windhoek@cashnetfinance.com.na",
    hours: {
      weekday: "07:30 – 16:30",
      saturday: "08:00 – 12:30",
    },
  },
  okahandja: {
    name: "Okahandja Branch",
    phones: ["+264 81 790 9909"],
    email: "okahandja@cashnetfinance.com.na",
    hours: {
      weekday: "07:30 – 16:30",
      saturday: "08:00 – 12:30",
    },
  },
  katutura: {
    name: "Katutura Branch",
    phones: ["+264 81 142 4048"], // Using Windhoek's phone as requested
    email: "katutura@cashnetfinance.com.na",
    hours: {
      weekday: "07:30 – 16:30",
      saturday: "08:00 – 12:30",
    },
  },
  rundu: {
    name: "Rundu Branch",
    phones: ["+264 81 142 4048"], // Using Windhoek's phone as requested
    email: "rundu@cashnetfinance.com.na",
    hours: {
      weekday: "07:30 – 16:30",
      saturday: "08:00 – 12:30",
    },
  },
  oshakati: {
    name: "Oshakati Branch",
    phones: ["+264 81 142 4048"], // Using Windhoek's phone as requested
    email: "oshakati@cashnetfinance.com.na",
    hours: {
      weekday: "07:30 – 16:30",
      saturday: "08:00 – 12:30",
    },
  },
  swakopmund: {
    name: "Swakopmund Branch",
    phones: ["+264 81 142 4048"], // Using Windhoek's phone as requested
    email: "swakopmund@cashnetfinance.com.na",
    hours: {
      weekday: "07:30 – 16:30",
      saturday: "08:00 – 12:30",
    },
  },
  walvisbay: {
    name: "Walvisbay Branch",
    phones: ["+264 81 142 4048"], // Using Windhoek's phone as requested
    email: "walvisbay@cashnetfinance.com.na",
    hours: {
      weekday: "07:30 – 16:30",
      saturday: "08:00 – 12:30",
    },
  },
};

const Contact: React.FC = () => {
  const handlePhoneCall = (phone: string) => {
    window.location.href = `tel:${phone}`;
  };

  const handleWhatsApp = (phone: string) => {
    const whatsappNumber = phone.replace(/[^0-9]/g, "");
    window.location.href = `https://wa.me/${whatsappNumber}`;
  };

  const handleEmail = (email: string) => {
    window.location.href = `mailto:${email}`;
  };

  return (
    <div className="w-full bg-gray-50 py-8">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-800 mb-4">Contact Us</h1>
          <p className="text-lg text-gray-600">
            Please select the branch and method you wish to contact the branch at.
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl mx-auto">
          {Object.values(BranchInfo).map((branch) => (
            <div
              key={branch.name}
              className="bg-white rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow"
            >
              <h3 className="text-xl font-semibold text-gray-800 mb-4">
                {branch.name}
              </h3>
              <div className="space-y-3 mb-6">
                <div className="flex items-start gap-3 text-gray-600">
                  <Clock size={20} className="flex-shrink-0 mt-1" />
                  <div className="flex flex-col">
                    <span>Mon-Fri: {branch.hours.weekday}</span>
                    <span>Sat: {branch.hours.saturday}</span>
                  </div>
                </div>
              </div>

              <div className="space-y-3">
                <Link 
                  to={`/locations/${branch.name.split(" ")[0].toLowerCase()}`}
                  className="w-full bg-[#F85B20] text-white px-6 py-3 rounded-full hover:bg-[#E04E1A] transition-colors flex items-center justify-center gap-2"
                >
                  <FileText size={20} />
                  Apply Now
                </Link>

                <button
                  onClick={() => handlePhoneCall(branch.phones[0])}
                  className="w-full bg-blue-600 text-white px-6 py-3 rounded-full hover:bg-blue-700 transition-colors flex items-center justify-center gap-2"
                >
                  <Phone size={20} />
                  Call Us
                </button>

                <button
                  onClick={() => handleWhatsApp(branch.phones[0])}
                  className="w-full bg-green-600 text-white px-6 py-3 rounded-full hover:bg-green-700 transition-colors flex items-center justify-center gap-2"
                >
                  <Phone size={20} />
                  WhatsApp Us
                </button>

                <button
                  onClick={() => handleEmail(branch.email)}
                  className="w-full bg-gray-800 text-white px-6 py-3 rounded-full hover:bg-gray-900 transition-colors flex items-center justify-center gap-2"
                >
                  <Mail size={20} />
                  Email Us
                </button>
              </div>
            </div>
          ))}
        </div>
        
        <div className="mt-12 text-center">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Head Office</h2>
          <p className="text-lg text-gray-600">
            For general inquiries, please contact our head office
          </p>
          <div className="mt-4">
            <p className="text-gray-700">
              <strong>Email:</strong> info@cashnetfinance.com.na
            </p>
            <p className="text-gray-700">
              <strong>Phone:</strong> +264 81 142 4048
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
