import React, { useState, useRef } from "react";
import { Camera, X, Upload } from "lucide-react";
import FollowupMessage from "../FollowupMessage";

interface FormData {
  firstName: string;
  surname: string;
  idNumber: string;
  phone: string;
  email: string;
  message: string;
  branch: string;
  loanAmount: number;
}

interface FileUpload {
  file: File;
  preview: string;
}

interface DocumentUploads {
  bankStatement: FileUpload[];
  payslips: FileUpload[];
  idDocument: FileUpload[];
}

interface DocumentRequirements {
  [key: string]: number;
}

const BACKEND_URL = "https://cashnetfinance.com.na:3001";

// Add CSS styles to the head of the document
const addSliderStyles = () => {
  const styleElement = document.createElement("style");
  styleElement.innerHTML = `
    /* Make the slider handle significantly larger */
    input[type="range"].custom-slider::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: #f97316;
      cursor: pointer;
      border: none;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
    
    input[type="range"].custom-slider::-moz-range-thumb {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: #f97316;
      cursor: pointer;
      border: none;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
    
    input[type="range"].custom-slider::-ms-thumb {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: #f97316;
      cursor: pointer;
      border: none;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
  `;
  document.head.appendChild(styleElement);

  return () => {
    document.head.removeChild(styleElement);
  };
};

const OshikatiLoanForm = () => {
  // Add custom styles when component mounts
  React.useEffect(() => {
    const removeStyles = addSliderStyles();
    return () => {
      // Clean up styles when component unmounts
      removeStyles();
    };
  }, []);

  // Document requirements for each category
  const documentRequirements: DocumentRequirements = {
    bankStatement: 3,
    payslips: 2,
    idDocument: 2,
  };

  // Maximum allowed uploads per category (kept at 5)
  const maxUploadsPerType = 5;

  const [formData, setFormData] = useState<FormData>({
    firstName: "",
    surname: "",
    idNumber: "",
    phone: "",
    email: "",
    message: "",
    branch: "Oshakati", // Pre-selected branch
    loanAmount: 1000,
  });

  const [documents, setDocuments] = useState<DocumentUploads>({
    bankStatement: [],
    payslips: [],
    idDocument: [],
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [showFollowupMessage, setShowFollowupMessage] = useState(false);

  // State to track temporary input value while typing
  const [tempInputValue, setTempInputValue] = useState(
    formData.loanAmount.toString()
  );

  // Refs for file inputs
  const fileInputRefs = {
    bankStatement: useRef<HTMLInputElement>(null),
    payslips: useRef<HTMLInputElement>(null),
    idDocument: useRef<HTMLInputElement>(null),
  };

  // Camera refs
  const cameraRefs = {
    bankStatement: useRef<HTMLInputElement>(null),
    payslips: useRef<HTMLInputElement>(null),
    idDocument: useRef<HTMLInputElement>(null),
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Update the tempInputValue when the slider changes
  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    setFormData((prev) => ({
      ...prev,
      loanAmount: value,
    }));
    setTempInputValue(value.toString());
  };

  const handleAmountInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Get the raw input value with only numeric characters
    const inputValue = e.target.value.replace(/[^0-9]/g, "");

    // If the input is empty or just being cleared, allow it temporarily
    // Don't reset to 1000 immediately
    if (inputValue === "") {
      // Set a temporary empty string value instead of defaulting to 1000
      // We'll use a different state to track the raw input
      setTempInputValue("");
      return;
    }

    // Store the raw input value
    setTempInputValue(inputValue);

    // Parse to number for the form data
    const value = parseInt(inputValue);

    // Update the form data with the numeric value
    setFormData((prev) => ({ ...prev, loanAmount: value }));
  };

  // Add a separate handler for when input loses focus to enforce limits
  const handleAmountInputBlur = () => {
    // If the field was left empty, reset to minimum value
    if (tempInputValue === "") {
      setFormData((prev) => ({ ...prev, loanAmount: 1000 }));
      setTempInputValue("1000");
      return;
    }

    // Now apply the limits
    const limitedValue = Math.max(1000, Math.min(100000, formData.loanAmount));
    setFormData((prev) => ({
      ...prev,
      loanAmount: limitedValue,
    }));
    setTempInputValue(limitedValue.toString());
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    documentType: keyof DocumentUploads
  ) => {
    const files = event.target.files;
    if (!files) return;

    // Check if adding these files would exceed the max file limit (still 5)
    if (documents[documentType].length + files.length > maxUploadsPerType) {
      setError(`Maximum ${maxUploadsPerType} files allowed per document type`);
      return;
    }

    const newUploads: FileUpload[] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > 20 * 1024 * 1024) {
        // 20MB limit
        setError("File size should not exceed 20MB");
        continue;
      }

      const preview = URL.createObjectURL(file);
      newUploads.push({ file, preview });
    }

    setDocuments((prev) => ({
      ...prev,
      [documentType]: [...prev[documentType], ...newUploads],
    }));

    // Reset the input
    event.target.value = "";
  };

  const removeFile = (documentType: keyof DocumentUploads, index: number) => {
    setDocuments((prev) => ({
      ...prev,
      [documentType]: prev[documentType].filter((_, i) => i !== index),
    }));
  };

  const handleCameraCapture = (documentType: keyof DocumentUploads) => {
    const cameraInput = cameraRefs[documentType].current;
    if (cameraInput) {
      cameraInput.click();
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      // Allow form to submit even with partial document uploads
      // We'll just show a warning if some documents are missing
      const missingDocTypes = [];

      if (documents.bankStatement.length === 0) {
        missingDocTypes.push("Bank Statement");
      }
      if (documents.payslips.length === 0) {
        missingDocTypes.push("Payslips");
      }
      if (documents.idDocument.length === 0) {
        missingDocTypes.push("ID Document");
      }

      // Only show warning if some document types are completely missing
      if (missingDocTypes.length > 0) {
        const warning = `You haven't uploaded any ${missingDocTypes.join(
          ", "
        )}. Continuing with submission anyway.`;
        console.warn(warning);
        // Optional: display warning to user but don't block submission
        // setError(warning);
      }

      const formDataToSend = new FormData();

      // Append form fields
      Object.entries(formData).forEach(([key, value]) => {
        formDataToSend.append(key, value.toString());
      });

      // Append files with their respective types
      Object.entries(documents).forEach(
        ([type, files]: [string, FileUpload[]]) => {
          files.forEach(({ file }: FileUpload) => {
            formDataToSend.append(type, file);
          });
        }
      );

      const response = await fetch(`${BACKEND_URL}/api/submit-loan`, {
        method: "POST",
        body: formDataToSend,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to submit application");
      }

      setSuccess(true);
      // Show the followup message prompt before resetting form data
      setShowFollowupMessage(true);
      setDocuments({ bankStatement: [], payslips: [], idDocument: [] });
      // Don't reset form data here, we'll do it after the followup message is closed
    } catch (err) {
      setError(err instanceof Error ? err.message : "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const getUploadProgressText = (
    type: keyof DocumentUploads,
    required: number
  ) => {
    const current = documents[type].length;
    return `${current}/${required} documents uploaded`;
  };

  const FileUploadSection = ({
    title,
    type,
    accept = "image/*,application/pdf",
  }: {
    title: string;
    type: keyof DocumentUploads;
    accept?: string;
  }) => (
    <div className="mb-6">
      <label className="block text-gray-700 text-sm font-medium mb-2">
        {title}{" "}
        <span className="text-gray-500">
          (Upload {documentRequirements[type]} document
          {documentRequirements[type] > 1 ? "s" : ""})
        </span>
      </label>
      <div className="border-2 border-dashed border-gray-200 rounded-lg p-6 hover:border-orange-500 transition-colors">
        <div className="flex flex-wrap gap-4 mb-4">
          {documents[type].map((doc, index) => (
            <div key={index} className="relative">
              <img
                src={doc.preview}
                alt={`${type} ${index + 1}`}
                className="w-20 h-20 object-cover rounded"
              />
              <button
                onClick={() => removeFile(type, index)}
                className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1"
                type="button"
              >
                <X size={16} />
              </button>
            </div>
          ))}
        </div>

        <div className="flex justify-center space-x-4">
          <button
            type="button"
            onClick={() => fileInputRefs[type].current?.click()}
            className="flex items-center px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-700 hover:bg-gray-50"
          >
            <Upload className="w-4 h-4 mr-2" />
            Upload Files
          </button>

          <button
            type="button"
            onClick={() => handleCameraCapture(type)}
            className="flex items-center px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-700 hover:bg-gray-50"
          >
            <Camera className="w-4 h-4 mr-2" />
            Take Photo
          </button>
        </div>

        <input
          ref={fileInputRefs[type]}
          type="file"
          accept={accept}
          multiple
          onChange={(e) => handleFileChange(e, type)}
          className="hidden"
        />

        <input
          ref={cameraRefs[type]}
          type="file"
          accept="image/*"
          capture="environment"
          onChange={(e) => handleFileChange(e, type)}
          className="hidden"
        />

        <p className="mt-2 text-sm text-gray-500 text-center">
          {getUploadProgressText(type, documentRequirements[type])}
        </p>
      </div>
    </div>
  );

  return (
    <div className="w-full max-w-4xl mx-auto bg-white p-4 sm:p-8 rounded-xl shadow-lg">
      {/* Followup Message Modal */}
      {showFollowupMessage && (
        <FollowupMessage
          firstName={formData.firstName}
          surname={formData.surname}
          branch="Oshakati"
          idNumber={formData.idNumber}
          email={formData.email}
          loanAmount={formData.loanAmount}
          onClose={() => {
            setShowFollowupMessage(false);
            // Reset form data after closing the modal
            setFormData({
              firstName: "",
              surname: "",
              idNumber: "",
              phone: "",
              email: "",
              message: "",
              branch: "Oshakati",
              loanAmount: 1000,
            });
          }}
        />
      )}
      {success && (
        <div className="mb-6 p-4 bg-green-100 text-green-700 rounded-lg">
          Application submitted successfully!
        </div>
      )}

      {error && (
        <div className="mb-6 p-4 bg-red-100 text-red-700 rounded-lg">
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Loan Amount Slider */}
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-medium mb-2">
            Loan Amount <span className="text-red-500">*</span>
          </label>
          <div className="flex flex-col space-y-4">
            <div className="relative">
              <input
                type="range"
                min="1000"
                max="100000"
                step="1000"
                value={formData.loanAmount}
                onChange={handleSliderChange}
                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-orange-500 custom-slider"
              />
            </div>

            <div className="flex justify-between items-center">
              <span className="text-sm text-gray-600">N$ 1,000</span>

              <div className="relative w-32">
                <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                  N$
                </span>
                <input
                  type="text"
                  value={tempInputValue}
                  onChange={handleAmountInputChange}
                  onBlur={handleAmountInputBlur}
                  className="w-full p-2 pl-8 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500 text-center"
                />
              </div>

              <span className="text-sm text-gray-600">N$ 100,000</span>
            </div>
          </div>
        </div>

        {/* Personal Information */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-medium mb-2">
              First Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              required
              className="w-full p-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>

          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-medium mb-2">
              Surname <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="surname"
              value={formData.surname}
              onChange={handleInputChange}
              required
              className="w-full p-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>
        </div>

        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-medium mb-2">
            ID Number <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="idNumber"
            value={formData.idNumber}
            onChange={handleInputChange}
            required
            className="w-full p-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-medium mb-2">
              Phone Number <span className="text-red-500">*</span>
            </label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              required
              className="w-full p-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>

          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-medium mb-2">
              Email Address <span className="text-red-500">*</span>
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
              className="w-full p-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>
        </div>

        {/* Document Upload Sections */}
        <FileUploadSection
          title="Bank Statement (Last 3 months)"
          type="bankStatement"
        />
        <FileUploadSection title="Payslips (Last 2 months)" type="payslips" />
        <FileUploadSection
          title="ID Document (Front & Back)"
          type="idDocument"
        />

        {/* Branch Selection - Hardcoded to Oshakati */}
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-medium mb-2">
            Branch <span className="text-red-500">*</span>
          </label>
          <div className="space-y-2">
            <div
              className="flex items-center space-x-2 p-2 bg-gray-100 rounded-lg"
            >
              <div className="w-4 h-4 rounded-full border border-orange-500 bg-orange-500 flex items-center justify-center">
                <div className="w-2 h-2 rounded-full bg-white"></div>
              </div>
              <span className="text-gray-700 font-medium">Oshakati</span>
            </div>
          </div>
          
          {/* Hidden real radio input for form submission */}
          <div className="hidden">
            <input
              type="radio"
              name="branch"
              value="Oshakati"
              checked={true}
              readOnly
            />
          </div>
        </div>

        {/* Message */}
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-medium mb-2">
            Message
          </label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500 h-32 resize-none"
          />
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          disabled={loading}
          className={`w-full bg-orange-500 text-white py-3 px-6 rounded-lg hover:bg-orange-600 transition-colors focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 ${
            loading ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          {loading ? "Submitting..." : "Submit Application"}
        </button>
      </form>
    </div>
  );
};

export default OshikatiLoanForm;
