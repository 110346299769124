import React from "react";

const Locations: React.FC = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <p>Placeholder Text</p>
    </div>
  );
};

export default Locations;
