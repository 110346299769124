import React, { useState } from "react";

const LoanCalculator: React.FC = () => {
  const [amount, setAmount] = useState<number>(1000);

  const calculateMonthlyPayment = (
    principal: number,
    months: number
  ): number => {
    // Administration fee (H column in Excel)
    const adminFee = principal * 0.01; // 1% of loan amount

    if (months === 1) {
      // Month 1 formula: ((B4*0.23)+B4+H4)
      return principal * 0.23 + principal + adminFee;
    } else {
      // Months 2-5 formula: (((B4*0.3)+B4+H4)/months)
      return (principal * 0.3 + principal + adminFee) / months;
    }
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value) && value >= 500 && value <= 50000) {
      setAmount(value);
    }
  };

  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(Number(e.target.value));
  };

  return (
    <div className="bg-[#F85B20] p-8 rounded-3xl shadow-lg w-full max-w-xl">
      <div className="space-y-8">
        <div className="space-y-3">
          <h2 className="text-2xl font-medium text-white">Type loan Amount</h2>
          <input
            type="number"
            value={amount}
            onChange={handleAmountChange}
            min="500"
            max="50000"
            className="w-full p-4 rounded-lg text-black text-lg bg-white shadow-sm"
          />
        </div>

        <div className="space-y-2">
          <div className="relative">
            <input
              type="range"
              min="500"
              max="50000"
              value={amount}
              onChange={handleSliderChange}
              className="w-full h-2 bg-white rounded-lg appearance-none cursor-pointer"
            />
          </div>
          <div className="text-center text-white text-xl">
            {amount.toLocaleString()}
          </div>
        </div>

        <div className="space-y-4">
          <h3 className="text-xl text-white font-medium">
            Estimated Loan Repayment
          </h3>
          <div className="grid gap-3">
            {[1, 2, 3, 4, 5].map((months) => (
              <div
                key={months}
                className="flex items-center justify-between text-white py-1"
              >
                <span className="text-lg">
                  {months} {months === 1 ? "month" : "months"}
                </span>
                <div className="bg-white/20 px-6 py-2 rounded-lg min-w-[140px] text-right backdrop-blur-sm">
                  N$ {calculateMonthlyPayment(amount, months).toFixed(2)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanCalculator;
