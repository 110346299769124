import React from "react";
import WalvisbayLoanForm from "../../components/locationForms/WalvisBayLoanForm";

const WalvisbayLocation: React.FC = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">
        Apply for a Loan - Walvisbay
      </h1>
      <WalvisbayLoanForm />
    </div>
  );
};

export default WalvisbayLocation;
