import React from "react";
import ageIcon from "../assets/age-verification-icon.svg";
import employedIcon from "../assets/employment-icon-circular.svg";
import bankIcon from "../assets/revised-bank-account-icon.svg";
import salaryIcon from "../assets/monthly-salary-icon.svg";

const QualifySection: React.FC = () => {
  return (
    <div className="w-full bg-gray-900 py-16">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-white mb-12">
          How To Qualify For A Loan
        </h2>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 max-w-4xl mx-auto">
          {/* Age Requirement */}
          <div className="flex flex-col items-center">
            <div className="mb-4 flex items-center justify-center">
              <img src={ageIcon} alt="Age requirement" className="w-24 h-24" />
            </div>
            <p className="text-white text-center text-sm">
              Be 18 years or Above
            </p>
          </div>

          {/* Employment */}
          <div className="flex flex-col items-center">
            <div className="mb-4 flex items-center justify-center">
              <img
                src={employedIcon}
                alt="Employment status"
                className="w-24 h-24"
              />
            </div>
            <p className="text-white text-center text-sm">
              Be permanently employed
            </p>
          </div>

          {/* Bank Account */}
          <div className="flex flex-col items-center">
            <div className="mb-4 flex items-center justify-center">
              <img src={bankIcon} alt="Bank account" className="w-24 h-24" />
            </div>
            <p className="text-white text-center text-sm">
              Have a bank account through which your salary is paid
            </p>
          </div>

          {/* Monthly Salary */}
          <div className="flex flex-col items-center">
            <div className="mb-4 flex items-center justify-center">
              <img
                src={salaryIcon}
                alt="Monthly salary"
                className="w-24 h-24"
              />
            </div>
            <p className="text-white text-center text-sm">
              Have a monthly salary
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QualifySection;
